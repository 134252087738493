<template>
  <Dialog :name="name" width="560px" @close="handleBeforeClose">
    <template #title>
      <div>{{ getTitle }} авто</div>
    </template>
    <div>
      <el-form
        ref="formEditWhiteList"
        :model="formEditWhiteList"
        :rules="rules"
        label-width="180px"
        @submit.native.prevent
      >
        <el-form-item label="Номер авто" prop="plate_truck">
          <el-input
            v-model="formEditWhiteList.plate_truck"
            placeholder="Укажите номер авто"
            size="medium"
            :disabled="isEdit"
            @focus="$refs.formEditWhiteList.clearValidate('plate_truck')"
          />
        </el-form-item>

        <el-form-item label="Время нахождения" class="duration">
          <div class="duration__content">
            <div>
              <el-radio v-model="hasDuration" :label="true">
                До даты (включительно)
              </el-radio>
              <IqSelectDateForm
                v-model="dateUntil"
                :options="options"
                type="date"
                format="dd.MM.yyyy"
                placeholder="Укажите дату"
                :disabled="!hasDuration"
                size="large"
                :width="datepickerWidth"
                :clearable="false"
              />
            </div>
            <el-radio v-model="hasDuration" :label="false">Бессрочно</el-radio>
          </div>
        </el-form-item>
        <el-form-item label="Причина добавления" prop="reason">
          <el-input
            v-model="formEditWhiteList.reason"
            placeholder="Укажите причину добавления (максимум 255 символов)"
            type="textarea"
            :rows="6"
            resize="none"
            :maxlength="255"
            @focus="$refs.formEditWhiteList.clearValidate('reason')"
          />
        </el-form-item>

        <el-form-item label="Кто добавил" prop="white_by">
          <el-input
            v-model="formEditWhiteList.white_by"
            placeholder="Укажите своё ФИО"
            size="medium"
            :disabled="isEdit"
            @focus="$refs.formEditWhiteList.clearValidate('white_by')"
          />
        </el-form-item>

        <div class="buttons">
          <iq-button
            :class="buttonWidth"
            :loading="isShowLoader"
            @onClick="handleSubmitForm"
          >
            {{ getTextBtn }}
          </iq-button>
        </div>
      </el-form>
    </div>
  </Dialog>
</template>

<script>
import {
  ADD_WHITELIST,
  GET_IS_LOADING_WHITELIST,
  UPDATE_WHITELIST,
} from '@/views/control/store/actions'
import { CONTROL_TABLE_WHITELIST_EDIT_ROW } from '@/constants/dialogs'
import { GET_IS_MOBILE, GET_TERMINAL_CURRENT_ID } from '@/store/actions'
import { dateTzInTwoWeeks, getBlacklistTerm, pickerOptions } from '@/core'
import { mapActions, mapGetters } from 'vuex'
import { ruleFormEditWhitelist } from './data/rules'
import Dialog from '@/UI/dialog/Dialog'
import IqButton from '@/UI/buttons/IqButton'
import IqSelectDateForm from '@/UI/select/IqSelectDateForm.vue'

export default {
  name: 'DialogEditWhitelist',
  components: { Dialog, IqButton, IqSelectDateForm },
  props: { params: { type: Object, default: () => ({}) } },
  data() {
    return {
      name: CONTROL_TABLE_WHITELIST_EDIT_ROW,
      formEditWhiteList: {
        plate_truck: '',
        reason: '',
        white_by: '',
      },
      hasDuration: true,
      dateUntil: '',
      rules: ruleFormEditWhitelist,
      options: pickerOptions,
    }
  },
  computed: {
    ...mapGetters({
      isShowLoader: GET_IS_LOADING_WHITELIST,
      isMobile: GET_IS_MOBILE,
      currentTerminal: GET_TERMINAL_CURRENT_ID,
    }),

    dialogData() {
      return this.getDialog(this.name).data || {}
    },
    isEdit() {
      return Boolean(this.dialogData?.id)
    },
    getTitle() {
      return this.isEdit ? 'Изменение' : 'Добавление'
    },
    getTextBtn() {
      return this.isEdit ? 'Обновить' : 'Добавить'
    },
    buttonWidth() {
      return this.isMobile ? 'mobile' : 'desktop'
    },
    datepickerWidth() {
      return this.isMobile ? '100%' : '135px'
    },
  },
  watch: {
    dialogData() {
      this.setFormFields()

      if (this.isEdit) {
        this.setFormFields(this.dialogData)
      }
    },
  },
  methods: {
    ...mapActions({
      addWhitelist: ADD_WHITELIST,
      updateWhitelist: UPDATE_WHITELIST,
    }),
    handleSubmitForm() {
      this.$refs.formEditWhiteList.validate(valid => {
        if (valid) {
          const callback = this.onSuccess
          const params = this.params
          const requestData = {
            ...this.formEditWhiteList,
            white_until: this.hasDuration
              ? getBlacklistTerm(this.dateUntil)
              : '',
          }

          if (this.isEdit) {
            requestData.id = this.dialogData.id
            this.updateWhitelist({ requestData, callback, params })
          } else {
            const requestArray = {
              unload_id: this.currentTerminal,
              data: [requestData],
            }

            this.addWhitelist({ requestArray, callback, params })
          }
        }
      })
    },
    onSuccess() {
      this.handleBeforeClose()
      this.setDialog({ name: this.name })
    },
    handleBeforeClose() {
      this.setFormFields()
      this.$refs.formEditWhiteList.clearValidate()
    },
    setFormFields(data = {}) {
      this.formEditWhiteList.plate_truck = data.plate_truck
      this.formEditWhiteList.reason = data.reason
      this.formEditWhiteList.white_by = data.white_by
      this.hasDuration = this.isEdit ? Boolean(data.white_until) : true
      this.dateUntil = this.hasDuration
        ? data.white_until || dateTzInTwoWeeks()
        : dateTzInTwoWeeks()
    },
  },
}
</script>

<style lang="sass" scoped>
.el-form-item
  margin-bottom: 20px !important
  @media (min-width: 1200px)
    margin-bottom: 32px !important

  &.duration
    @media (min-width: 1200px)
      display: flex
      margin-bottom: 0 !important
    :deep(.el-form-item__label)
      display: block
      float: none
    :deep(.el-form-item__content)
      margin-left: 0 !important

    .duration__content
      display: flex
      flex-direction: column
      @media (min-width: 1200px)
        flex-direction: row
        align-items: baseline

.buttons
  display: flex
  justify-content: flex-end
  .mobile
    width: 100%
  .desktop
    width: 200px
</style>
